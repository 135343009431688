<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo-black.jpg" />
    <section class="slogan">COMING SOON</section>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
body {
  background-color: #161618;
}

img {
  max-width: 100%;
  height: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #161618;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.slogan {
  color: white;
  font-family: 'archivo';
  font-size: 30px;
}

@font-face {
  font-family: 'archivo';
  src: url('~@/assets/fonts/ArchivoBold.ttf') format('truetype');
}
</style>
